body, html, #root {
  margin: 0;
  padding: 0;
  height: 100%;
}

.Films {
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: 2;
}

img {
  align-items: center;
  margin: 0 auto;      /* Centre l'image horizontalement */
  max-width: 100%;     /* Empêche l'image de dépasser la taille de son conteneur */
  height: auto;        /* Maintient les proportions de l'image */
}

h1, h3, h4, h5, h6, p, a, div, span, li, ul, ol, footer, header, main, section, article {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
}

h2  {
  font-family: 'Helvetica', Arial, sans-serif;
  font-size: 15px;
  font-weight: 900;
}

a   {
font-family: 'Helvetica', Arial, sans-serif;
  font-size: 25px;
  font-weight: 900;
  text-decoration: none;
  color: black; /* couleur des liens non visités */
  text-decoration: none; /* ou ce que vous voulez pour le style */
}

a:visited {
  color: black; /* couleur des liens visités */
}

a:hover {
  color: blue; /* couleur des liens au survol */
}

a:focus {
  color: blue;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative; 
  z-index: 0;
}

header, footer {
  position: absolute; /* Positionne le header et le footer par rapport à leur conteneur parent */
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /*color: blue; */
  z-index: 1; /* Place le header et le footer au-dessus de l'image de fond */
}

header {
  top: 0; /* Fixe le header en haut de la page */
}

footer {
  bottom: 0; /* Fixe le footer en bas de la page */
}

header a, footer a {
  margin: 18px;
}

.left, .right, .center {
  display: flex;
  align-items: center;
}

.Films {
  width: 50%;
  flex: 1;
  margin: 50px 20px;
  margin-bottom: 40px;
}

.Films p {
  text-align: left;
}

.About {
  margin-left: 40px;
  width: 50%; /* Limite la largeur du contenu à 50% de la page */
  flex: 1;
  margin: 50px 20px;
}

.About .exhibited {
  margin-top: 40px; /* Ajustez cette valeur selon la quantité d'espace désirée */
}


.Contact {
  flex-grow: 1;
  margin: 50px 20px;
}

.Home {
  flex-grow: 1;
  background-image: url('SolarNoon.jpg');
  justify-content: space-between;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;
  z-index: -1; /* L'image de fond est placée en dessous */
  padding: 20px 5%;
}

.Films h2{
  position: relative;
  z-index: 3;
}


.Films h2:hover, .Films h2:active, .Films h2:focus {
  color: blue;
}


/* Responsive Design */
@media (max-width: 768px) {
  header, footer {
    align-items: flex-start;
  }
  .Films {
    width: 90%; 
  }
  .About {
    width: 90%; 

  }
  
}